import {
  DateField,
  List,
  TextField,
  useNotify,
  useRedirect,
  useTranslate,
  Show,
  SimpleShowLayout,
  Toolbar,
  useDataProvider,
  useRecordContext,
  ReferenceField,
  useListContext,
  EmailField,
  WrapperField,
  UrlField,
  ImageField,
  useRefresh,
  SimpleForm,
  DateInput,
  Edit,
  required
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import EnumTextField from '../common_modules/components/EnumTextField';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import { useEffect, useState } from 'react';
import { Box, Card, CardContent, CardMedia, Grid, Theme, Button, Typography, useMediaQuery } from '@mui/material';
import dataProvider from '../dataProvider';
import ZoomableImageField from '../common_modules/components/ZoomableImageField';
import PostalCodeField from '../common_modules/components/PostalCodeField';
import FlexibleListLayout from '../common_modules/components/FlexibleListLayout';
import PercentNumberField from '../common_modules/components/PercentNumberField';
import PercentNumberInput from '../common_modules/components/PercentNumberInput';
import NumericRadioButtonGroupInput from '../common_modules/components/NumericRadioButtonGroupInput';
import { Choice, INVALD_FLAG } from '../common_modules/constants/choices';

/**
 * TODO: ECサイトを画像表示にするサンプル
 */
const ClientCard = ({ client }: { client: any }) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const imageBlob = await dataProvider.getBlob(`clients/${client.id}/image`);
        const imageObjectUrl = URL.createObjectURL(imageBlob);
        setImageUrl(imageObjectUrl);
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };
    fetchImage();
  }, [client.id]);

  return (
    <Card sx={{ width: '180px', margin: 'auto' }}>
      <CardMedia component="img" height="150" image={imageUrl} alt={client.name} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {client.name}
        </Typography>
      </CardContent>
    </Card>
  );
};

const ClientGrid = () => {
  const { data, isLoading } = useListContext();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Grid container spacing={2}>
      {data?.map((client) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={client.id}>
          <ClientCard client={client} />
        </Grid>
      ))}
    </Grid>
  );
};

export const AffiliatesList = () => {
  const translate = useTranslate();
  return (
    <List resource="partners" exporter={false} sort={{ field: 'applyStatus', order: 'ASC' }}>
      <FlexibleListLayout rowClick="show" bulkActionButtons={false}>
        <EnumTextField
          source="applyStatus"
          label={translate('resources.partners.fields.applyStatus')}
          translationKeyPrefix="values.applyStatus"
        />
        <TextField source="id" label={translate('resources.partners.fields.id')} />
        <TextField source="name" />
        <DateField showTime={true} source="expirationDate" />
        <EnumTextField source="invalidFlag" translationKeyPrefix="values.invalidFlag" />
      </FlexibleListLayout>
    </List>
  );
};

const EDIT_APPLY_STATUS: Choice[] = [
  { id: 2, name: '承認' },
  { id: 3, name: '却下' }
];

export const AffiliateEditContent = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  const handleError = useErrorHandler();
  const notify = useNotify();
  const redirect = useRedirect();
  if (!record) {
    return;
  }
  const handleSubmit = async (data: FieldValues) => {
    try {
      const { applyStatus, invalidFlag, partnerReward } = data as {
        applyStatus: number;
        invalidFlag: number;
        partnerReward: string;
      };

      await dataProvider.update('partners', {
        id: record.id,
        data: { applyStatus, invalidFlag, partnerReward },
        previousData: record
      });

      notify('保存しました。');
      redirect('list', 'partners');
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Box>
      <SimpleShowLayout sx={{ pb: 0 }}>
        <EnumTextField source="applyStatus" translationKeyPrefix="values.applyStatus" />
        <TextField source="id" label={translate('resources.partners.fields.id')} />
        <TextField source="name" />
        <EnumTextField source="partnerType" translationKeyPrefix={'values.partnerType'} />
        <EmailField source="email" />
        <UrlField source="website" />
        <TextField source="tel" />
        <WrapperField label="resources.partners.fields.address" source="prefecture">
          <div>
            <PostalCodeField source="zipCode" />
          </div>
          <TextField source="prefecture" />
          <TextField source="city" />
          <TextField source="address1" />
          <TextField source="address2" />
        </WrapperField>
        {record.partnerType === 2 && <TextField source="dunsNumber" />}

        <DateField source="expirationDate" label={translate('resources.partners.fields.expirationDate')} showTime />
        {/* <CustomActions /> */}
      </SimpleShowLayout>
      <Edit mutationMode="pessimistic" mutationOptions={{ onError: handleError }} title=" ">
        <SimpleForm onSubmit={handleSubmit} sx={{ pt: 0 }}>
          <PercentNumberInput source="partnerReward" validate={required()} />
          <Box display="flex" flexDirection="column" sx={{ mb: 1 }}>
            <Typography variant="caption" color="text.secondary">
              {translate('resources.partners.fields.introducerReward')}
            </Typography>
            <PercentNumberField source="introducerReward" />
          </Box>
          {record?.applyStatus === 1 && (
            <>
              <Typography variant="body1" gutterBottom>
                上記の申請内容を確認して、承認または却下を選択してください。
              </Typography>
              <NumericRadioButtonGroupInput source="applyStatus" choices={EDIT_APPLY_STATUS} />
            </>
          )}
          {record?.applyStatus === 2 && (
            <>
              <Typography variant="body1" gutterBottom>
                以下のボタンでアフィリエイトIDのステータスを切り替えることが可能です。
              </Typography>
              <NumericRadioButtonGroupInput source="invalidFlag" choices={INVALD_FLAG} />
            </>
          )}
        </SimpleForm>
      </Edit>
    </Box>
  );
};

export const AffiliateEdit = () => {
  return (
    <Show resource="partners" actions={false}>
      <AffiliateEditContent />
    </Show>
  );
};

export default { AffiliatesList, AffiliateEdit };
