import React, { useEffect, useState } from 'react';
import { Container, Typography, Link, Box, useMediaQuery, Stack } from '@mui/material';
import { useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { styled } from '@mui/material/styles';
import { getInviteSrcData } from '../common_modules/components/Common';
import RegisterSubmitButton from '../common_modules/components/RegisterSubmitButton';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import RegisterLayout from '../common_modules/components/RegisterLayout';
import SiteLogo from '../common_modules/components/SiteLogo';
import Divider from '@mui/material/Divider';

const steps = ['利用契約', 'ECサイト契約', '登録情報入力', '登録内容確認', '申請', '審査', '承認', '登録完了'];

const CustomDivider = styled(Divider)(({ theme }) => ({
  borderColor: '#000000'
}));

const StartRegistration: React.FC = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  // 各フォームの状態を個別に管理
  const [inviteSrc, setInviteSrc] = useState<number | null>(null);
  const [inviteSite, setInviteSite] = useState<string | null>(null);

  // ページ遷移時にセッションストレージのトークンをチェック
  useEffect(() => {
    const registerToken = sessionStorage.getItem('register_token'); // セッションストレージからトークンを取得
    if (!registerToken) {
      // トークンが存在しない場合は token-error ページにリダイレクト
      navigate('/register/client/token-error', { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    const data = getInviteSrcData();
    setInviteSrc(data);
    const site = sessionStorage.getItem('inviteSite');
    setInviteSite(site);
  }, []);

  // 下部フォームの「次へ」ボタンが押された時
  const handleNext = () => {
    navigate('/register/client/terms');
  };

  const gap = isSmallScreen ? 3 : 5;

  return (
    <RegisterLayout>
      <Container>
        <Stack direction={'column'} gap={gap}>
          <Helmet>
            <title>ECサイト登録</title>
          </Helmet>
          <SiteLogo />
          <Typography variant="h4">ECサイト登録</Typography>

          {isSmallScreen ? (
            <Typography variant="body1">
              SuccessfeeのECサイト登録を行います。登録に必要な情報をお確かめの上、登録を進めてください。
              <br />
              登録完了までは6ステップあります。
              <br />
              途中「ECサイト登録」は、次の画面へ進んだ段階で、そこまでの手続き内容が保存され、離脱後も次のステップから再開できます。
            </Typography>
          ) : (
            <Typography variant="body1">
              SuccessfeeのECサイト登録を行います。登録に必要な情報をお確かめの上、ステップを進めてください。
            </Typography>
          )}

          <Box display="flex" justifyContent="center">
            <Box
              display="flex"
              flexDirection="row"
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{ maxWidth: isSmallScreen ? '396px' : '684px', width: '100%' }}
            >
              {steps.map((label, index) => (
                <React.Fragment key={label}>
                  {isSmallScreen ? (
                    <Box component="img" src={`/images/img_step_sp${index + 1}.svg`} alt={`step${index + 1}`} />
                  ) : (
                    <Box component="img" src={`/images/img_step_pc${index + 1}.svg`} alt={`step${index + 1}`} />
                  )}
                  {/* 小さい画面では非表示 */}
                  {!isSmallScreen && index < steps.length - 1 && (
                    <Box
                      component="img"
                      src={`/images/step_arrow.svg`}
                      alt={'arrow'}
                      sx={{ width: '24px', height: '24px', padding: '4px 3px' }}
                    />
                  )}
                </React.Fragment>
              ))}
            </Box>
          </Box>

          {/* <CustomDivider /> */}

          {/* <Stack direction={'column'} gap={2}>
            <Typography variant="h5">振込/支払口座</Typography>
            <Typography variant="body1">
              サービス使用料や手数料の支払い、報酬の振込に使用する口座情報をご登録ください。
            </Typography>
          </Stack> */}

          {/* <Stack direction={'column'} gap={2}>
            <Typography variant="h5">身分証明書(個人登録の方)</Typography>
            <Typography variant="body1">
              個人登録の方は、マイナンバーカード、運転免許証などの身分証明書の、表と裏の両面をスキャンまたは撮影して提出してください。
              <br />
              対応形式：JPEG
              <br />
              ファイルサイズ：10MB以下
              <br />
            </Typography>
          </Stack> */}

          {/* 下部フォーム */}
          <Box display="flex" justifyContent="center">
            <RegisterSubmitButton onClick={handleNext}>次へ</RegisterSubmitButton>
          </Box>
        </Stack>
      </Container>
    </RegisterLayout>
  );
};

export default StartRegistration;
