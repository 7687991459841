import {
  Edit,
  SelectInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useTranslate,
  ImageInput,
  ImageField,
  useRefresh,
  useNotify
} from 'react-admin';
import Resizer from 'react-image-file-resizer';
import { EC_SITE_TYPE, PREFECTURE_LIST } from '../common_modules/constants/choices';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import NumericRadioButtonGroupInput from '../common_modules/components/NumericRadioButtonGroupInput';
import { Typography, Box } from '@mui/material';
import CustomUrlField from '../common_modules/components/CustomUrlField';
import EnumTextField from '../common_modules/components/EnumTextField';
import PaymentDateTextField from '../common_modules/components/PaymentDateTextField';
import FormValidation from '../common_modules/components/FormValidation'; // 追加: FormValidation のインポート
import PercentNumberField from '../common_modules/components/PercentNumberField';
import PercentNumberInput from '../common_modules/components/PercentNumberInput';

// カスタムツールバーを定義して削除ボタンを表示しないようにする
const CustomToolbar = ({ isFormValid }: { isFormValid: boolean }) => (
  <Toolbar style={{ backgroundColor: '#FFFFFF' }}>
    <SaveButton disabled={!isFormValid} /> {/* isFormValid の状態に応じてボタンを無効化 */}
  </Toolbar>
);

const resizeImageToBase64 = (
  file: File | null,
  maxWidth: number,
  maxHeight: number,
  format: string = 'PNG',
  quality: number = 100
): Promise<string | null> => {
  return new Promise((resolve) => {
    if (!file) {
      resolve(null);
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const widthRatio = maxWidth / img.width;
        const heightRatio = maxHeight / img.height;
        const scaleRatio = Math.min(widthRatio, heightRatio, 1);

        if (scaleRatio === 1) {
          // リサイズ不要
          resolve(event.target?.result as string);
        } else {
          const resizeWidth = img.width * scaleRatio;
          const resizeHeight = img.height * scaleRatio;

          Resizer.imageFileResizer(
            file,
            resizeWidth,
            resizeHeight,
            format,
            quality,
            0,
            (resizedImage) => {
              resolve(resizedImage as string);
            },
            'base64'
          );
        }
      };
      img.src = event.target?.result as string;
    };
    reader.readAsDataURL(file);
  });
};

export const SettingEdit = () => {
  const translate = useTranslate();
  const handleError = useErrorHandler();
  const refresh = useRefresh();
  const notify = useNotify();
  const handleTransform = async (data: Record<string, any>) => {
    // 現在の画像データを保持
    const currentStoreLogo = data.storeLogoThumbnail?.src || null;
    const currentBannerImage = data.bannerImage?.src || null;

    // リサイズ処理を実行
    const storeLogoFile = data.storeLogoThumbnail?.rawFile || null;
    const bannerImageFile = data.bannerImage?.rawFile || null;

    const resizedStoreLogo = await resizeImageToBase64(storeLogoFile, 180, 150, 'PNG');
    const resizedBannerImage = await resizeImageToBase64(bannerImageFile, 500, 500, 'PNG');

    return {
      ...data,
      storeLogoThumbnail: resizedStoreLogo || currentStoreLogo,
      bannerImage: resizedBannerImage || currentBannerImage
    };
  };
  // 必須フィールドの定義
  const requiredFields = [
    'name',
    'email',
    'tel',
    'zipCode',
    'prefecture',
    'city',
    'address1',
    'ecSiteUrl',
    'ecSiteType',
    'defaultPartnerReward'
  ];

  return (
    <Edit
      title={translate('resources.settings.name')}
      redirect={false}
      mutationMode="pessimistic"
      mutationOptions={{
        onError: handleError,
        onSuccess: () => {
          notify('更新しました', { type: 'info' });
          refresh();
        }
      }}
      transform={handleTransform}
    >
      <SimpleForm
        toolbar={
          <FormValidation requiredFields={requiredFields}>
            {(isFormValid) => <CustomToolbar isFormValid={isFormValid} />}
          </FormValidation>
        }
        sx={{ maxWidth: '600px' }}
      >
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="email" validate={required()} fullWidth />
        <TextInput source="tel" validate={required()} />
        <TextInput source="zipCode" validate={required()} />
        <SelectInput source="prefecture" validate={required()} choices={PREFECTURE_LIST} />
        <TextInput source="city" validate={required()} fullWidth />
        <TextInput source="address1" validate={required()} fullWidth />
        <TextInput source="address2" fullWidth />
        <TextInput source="ecSiteUrl" validate={required()} fullWidth />
        <NumericRadioButtonGroupInput source="ecSiteType" validate={required()} choices={EC_SITE_TYPE} />
        <Box sx={{ mb: 2 }}>
          <Typography variant="caption" color="textSecondary" gutterBottom display="block">
            {translate('resources.settings.fields.closingDate')}
          </Typography>
          <PaymentDateTextField source="closingDate" label="EEEE" />
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="caption" color="textSecondary" gutterBottom display="block">
            {translate('resources.settings.fields.invoiceDate')}
          </Typography>
          <PaymentDateTextField source="invoiceDate" />
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="caption" color="textSecondary" gutterBottom display="block">
            {translate('resources.settings.fields.plannedPaymentMonth')}
          </Typography>
          <EnumTextField source="plannedPaymentMonth" translationKeyPrefix="values.paymentMonth" />
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="caption" color="textSecondary" gutterBottom display="block">
            {translate('resources.settings.fields.plannedPaymentDate')}
          </Typography>
          <PaymentDateTextField source="plannedPaymentDate" />
        </Box>
        <PercentNumberInput source="defaultPartnerReward" validate={required()} />

        <Box sx={{ mb: 2 }}>
          <CustomUrlField source="inviteCodeUrl" label={'パートナー招待URL'} />
          <ImageField source="qrCode" sx={{ mt: 2 }} />
        </Box>

        <Box display="flex" flexDirection="column" justifyContent="flex-start">
          <ImageInput source="storeLogoThumbnail" label="ストア画像" accept="image/png">
            <ImageField source="src" />
          </ImageInput>
          <Typography variant="caption" color="textSecondary" gutterBottom>
            ストア画像はPNG形式でアップロードしてください。
            <br />
            幅180px、高さ150px以下にリサイズされます。
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" justifyContent="flex-start">
          <ImageInput source="bannerImage" label="バナー画像" accept="image/png,image/jpeg">
            <ImageField source="src" title="バナー画像" />
          </ImageInput>
          <Typography variant="caption" color="textSecondary" gutterBottom>
            バナー画像はJPEG/PNG形式でアップロードしてください。
            <br />
            幅500px、高さ500px以下にリサイズされます。
          </Typography>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default SettingEdit;
