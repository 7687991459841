import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Container,
  alpha,
  Stack,
  useMediaQuery,
  styled,
  Grid,
  Paper
} from '@mui/material';
import React, { ReactNode, useCallback, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { BANK_ACCOUNT_TYPE, EC_SITE_TYPE, getChoiceName } from '../common_modules/constants/choices';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import dataProvider from '../dataProvider';
import { getRegisterFormData } from '../common_modules/components/Common';
import { Helmet } from 'react-helmet';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import RegisterHeaderProgress from '../common_modules/components/RegisterHeaderProgress';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import RegisterLayout from '../common_modules/components/RegisterLayout';
import RegisterSubmitButton from '../common_modules/components/RegisterSubmitButton';
import LinkButton from '../common_modules/components/LinkButton';
import WrappedCheckBoxFormControlLabel from '../common_modules/components/WrappedCheckBoxFormControlLabel';
import AspectRatioBox from '../common_modules/components/AspectRatioBox';

const BorderStack = styled(Stack)(({}) => ({
  padding: '24px',
  borderColor: alpha('#000000', 0.5),
  borderWidth: '2px',
  borderStyle: 'solid',
  borderRadius: '4px'
}));

const Confirmation: React.FC = () => {
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState<any>({});

  const handleError = useErrorHandler();
  const translate = useTranslate();
  const [reCaptchaToken, setReCaptchaToken] = React.useState<string>('');

  const { executeRecaptcha } = useGoogleReCaptcha();
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  useEffect(() => {
    setIsConfirmed(false);
  }, []);

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('confirmation');
    setReCaptchaToken(token);
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // sessionStorageから画像データを取得し、formDataに設定
    const storeLogoThumbnail = sessionStorage.getItem('storeLogoThumbnail');
    const bannerImage = sessionStorage.getItem('bannerImage');

    const storedFormData = getRegisterFormData();
    if (storedFormData) {
      setFormData({
        ...storedFormData,
        storeLogoThumbnail,
        bannerImage
      });
    } else {
      navigate(`/register/client/error`);
    }
  }, [navigate]);

  const handleSubmit = async () => {
    try {
      let sendingFormData = getRegisterFormData() as any;
      if (!sendingFormData) {
        navigate(`/register/client/error`);
        return;
      }

      // sessionStorage から画像データを取得
      const storeLogoThumbnail = sessionStorage.getItem('storeLogoThumbnail');
      const bannerImage = sessionStorage.getItem('bannerImage');

      // 画像データをフォームデータに追加
      sendingFormData.storeLogoThumbnail = storeLogoThumbnail;
      sendingFormData.bannerImage = bannerImage;
      sendingFormData.reCaptchaToken = reCaptchaToken;

      const registerClientUrl = `/registers/clients/create`;
      setIsLoading(true);
      await dataProvider.register(sendingFormData, registerClientUrl);

      // 登録完了ページへ
      navigate(`/register/client/finish`);
    } catch (error) {
      handleError();
      handleReCaptchaVerify();
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmChange = () => {
    setIsConfirmed(!isConfirmed);
  };

  const renderField = (label: string, value: any) => (
    <Box>
      <Typography variant="caption" color="textSecondary" gutterBottom>
        {label}
      </Typography>
      <Typography>{value}</Typography>
    </Box>
  );

  return (
    <RegisterLayout>
      <Container>
        <Helmet>
          <title>登録内容確認</title>
        </Helmet>
        <Stack spacing={1}>
          <RegisterHeaderProgress
            title={'resources.registers.name.Confirmation'}
            icon={<ChecklistOutlinedIcon />}
            step={4}
            totalSteps={7}
          />
          <Stack spacing={isSmallScreen ? 3 : 5}>
            <Stack spacing={3}>
              <Typography variant={'h5'}>登録情報</Typography>
              <BorderStack spacing={2}>
                {renderField(translate('resources.registers.fields.name'), formData.name)}
                {renderField(translate('resources.registers.fields.zipCode'), formData.zipCode)}
                {renderField(translate('resources.registers.fields.prefecture'), formData.prefecture)}
                {renderField(translate('resources.registers.fields.city'), formData.city)}
                {renderField(translate('resources.registers.fields.address1'), formData.address1)}
                {renderField(translate('resources.registers.fields.address2'), formData.address2)}
                {renderField(translate('resources.registers.fields.tel'), formData.tel)}
                {renderField(translate('resources.registers.fields.email'), formData.email)}
                {renderField(
                  translate('resources.registers.fields.ecSiteType'),
                  getChoiceName(EC_SITE_TYPE, formData.ecSiteType)
                )}
                {renderField(translate('resources.registers.fields.ecSiteUrl'), formData.ecSiteUrl)}
                <Stack spacing={3}>
                  <BorderStack spacing={3}>
                    <Box sx={{ p: 0 }}>
                      <Grid container spacing={2} sx={{ m: 0, width: '100%' }}>
                        <Grid item xs={12} md={6} sx={{ p: 2 }}>
                          <Typography variant="caption" color="textSecondary">
                            ストア画像
                          </Typography>
                          <Box sx={{ mt: 1, height: formData.storeLogoThumbnail ? 'auto' : '40px' }}>
                            <AspectRatioBox
                              ratio={400 / 254}
                              sx={{ border: formData.storeLogoThumbnail ? '1px solid #000' : 'none' }}
                            >
                              {formData.storeLogoThumbnail ? (
                                <Box
                                  component="img"
                                  src={formData.storeLogoThumbnail}
                                  alt="ストア画像"
                                  sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain'
                                  }}
                                />
                              ) : (
                                <Typography variant="body1" color="textSecondary">
                                  なし
                                </Typography>
                              )}
                            </AspectRatioBox>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6} sx={{ p: 2 }}>
                          <Typography variant="caption" color="textSecondary" gutterBottom>
                            バナー画像
                          </Typography>
                          <Box sx={{ mt: 1, height: formData.bannerImage ? 'auto' : '40px' }}>
                            <AspectRatioBox
                              ratio={400 / 254}
                              sx={{ border: formData.bannerImage ? '1px solid #000' : 'none' }}
                            >
                              {formData.bannerImage ? (
                                <Box
                                  component="img"
                                  src={formData.bannerImage}
                                  alt="バナー画像"
                                  sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain'
                                  }}
                                />
                              ) : (
                                <Typography variant="body1" color="textSecondary">
                                  なし
                                </Typography>
                              )}
                            </AspectRatioBox>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </BorderStack>
                </Stack>
              </BorderStack>
            </Stack>

            {/* {formData.partnerType === 1 && (
              <Stack spacing={3}>
                <Typography variant={'h5'}>身分証明書</Typography>
                <BorderStack spacing={3}>
                  <Box sx={{ p: 0 }}>
                    <Grid container spacing={2} sx={{ m: 0, width: '100%' }}>
                      <Grid xs={12} md={6}>
                        <Typography variant="caption" color="textSecondary">
                          表面
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                          <AspectRatioBox ratio={400 / 254}>
                            <Box
                              component="img"
                              src={frontImage}
                              alt={'表面'}
                              sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain'
                              }}
                            />
                          </AspectRatioBox>
                        </Box>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <Typography variant="caption" color="textSecondary" gutterBottom>
                          裏面
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                          <AspectRatioBox ratio={400 / 254}>
                            <Box
                              component="img"
                              src={backImage}
                              alt={'表面'}
                              sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain'
                              }}
                            />
                          </AspectRatioBox>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </BorderStack>
              </Stack>
            )} */}

            <WrappedCheckBoxFormControlLabel
              control={<Checkbox checked={isConfirmed} onChange={handleConfirmChange} />}
              label="入力内容に間違いがないことを確認しました。"
            />
          </Stack>

          <Stack alignItems="center" spacing={3}>
            <RegisterSubmitButton
              onClick={handleSubmit}
              disabled={!isConfirmed || isLoading || reCaptchaToken.length === 0}
            >
              申請する
            </RegisterSubmitButton>
            <LinkButton href="/register/client/form">{'< 登録情報入力に戻る'}</LinkButton>
            {/* {formData.partnerType === 1 ? (
              <LinkButton href="/register/partner/upload-id">{'< 身分証明書選択に戻る'}</LinkButton>
            ) : (
              <LinkButton href="/register/partner/form">{'< 登録情報入力に戻る'}</LinkButton>
            )} */}
          </Stack>
        </Stack>
      </Container>
    </RegisterLayout>
  );
};

export default Confirmation;
