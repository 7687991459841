import { useEffect, useState } from 'react';
import {
  CreateBase,
  CreateButton,
  List,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  required,
  useNotify,
  useTranslate,
  Title,
  SelectInput,
  Toolbar
} from 'react-admin';
import { getLocalStorageId } from '../authProvider';
import { InputAdornment, Box, Typography, alpha, Stack, styled } from '@mui/material';
import EnumTextField from '../common_modules/components/EnumTextField';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import FlexibleListLayout from '../common_modules/components/FlexibleListLayout';
import FileUploadInput from '../common_modules/components/FileUploadInput';
import dataProvider from '../dataProvider';
import { useFormContext, useWatch } from 'react-hook-form';
import PercentNumberInput from '../common_modules/components/PercentNumberInput';

const CustomCreateButton = () => <CreateButton label="新規パートナーを招待" icon={<PersonAddAlt1Icon />} />;
const ListActions = () => (
  <TopToolbar>
    <CustomCreateButton />
  </TopToolbar>
);

export const InvitationList = () => {
  const translate = useTranslate();
  return (
    <List actions={<ListActions />}>
      <FlexibleListLayout bulkActionButtons={false}>
        <TextField source="inviteName" label={translate('resources.invitations.fields.inviteName')} />
        <TextField source="inviteEmail" label={translate('resources.invitations.fields.inviteEmail')} />
        <EnumTextField
          source="inviteStatus"
          translationKeyPrefix="values.inviteStatus"
          label={translate('resources.invitations.fields.inviteStatus')}
        />
      </FlexibleListLayout>
    </List>
  );
};

const BorderStack = styled(Stack)(() => ({
  padding: '24px',
  borderColor: alpha('#000000', 0.5),
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '4px',
  width: '100%'
}));

const CustomToolbarCSV = () => {
  const { control } = useFormContext();
  const selectedFile = useWatch({ control, name: 'file' });
  return <SaveButton label="CSVから招待メールを送信" icon={<div />} disabled={!selectedFile} />;
};

const CustomToolbar = ({ isFormValid, onSubmit }: { isFormValid: boolean; onSubmit?: () => void }) => (
  <SaveButton label="招待メールを送信" disabled={!isFormValid} onClick={onSubmit} />
);

interface ApiSelectInputProps {
  source: string;
  label: string;
}
const InvitablePartnersSelectInput: React.FC<ApiSelectInputProps> = ({ source, label }) => {
  const [choices, setChoices] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await dataProvider.getData(`invitable-partners`);
        const formattedChoices = data.map((item: any) => ({
          id: item.id,
          name: item.name
        }));
        setChoices(formattedChoices);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <SelectInput
      source={source}
      label={label}
      choices={choices}
      optionText="name"
      optionValue="id"
      validate={required()}
    />
  );
};

const PartnerRewardText = () => {
  const { setValue } = useFormContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await dataProvider.getData(`default-reward-setting`);
        setValue('partnerReward', data.partnerReward);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [setValue]);

  return <PercentNumberInput source="partnerReward" validate={required()} />;
};

const FormValidation = ({
  requiredFields,
  children
}: {
  requiredFields: string[];
  children: (isFormValid: boolean) => JSX.Element;
}) => {
  const { control } = useFormContext();
  const values = useWatch({ control, name: requiredFields });

  const isFormValid = requiredFields.every((field, index) => values[index]);

  return children(isFormValid);
};

export const InvitationCreate = () => {
  const handleError = useErrorHandler();
  const translate = useTranslate();
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const requiredFields = ['name', 'email', 'partnerReward'];

  // APIに送信する前にpartnerIdをparentPartnerIdに変換
  const handleSubmit = (data: any) => {
    const transformedData = { ...data };

    if (data.partnerId) {
      // partnerIdをparentPartnerIdに変更して追加
      transformedData.parentPartnerId = data.partnerId;

      // clientIdを取得してIntに変換し、追加
      const clientIdString = getLocalStorageId();
      transformedData.clientId = clientIdString ? parseInt(clientIdString, 10) : undefined;

      delete transformedData.partnerId; // partnerIdは削除
    }

    return transformedData;
  };

  const handleInputCSV = async (data: any) => {
    setIsLoading(true);
    try {
      const result = await dataProvider.bulkInvitations(data.file.rawFile);
      notify(`${result.length}件の招待メールを送信しました。`, { type: 'success', undoable: false });
    } catch (error) {
      handleError();
      return (error as any).errors;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Title title="新規パートナー招待" />
      <CreateBase
        redirect="list"
        transform={handleSubmit}
        mutationOptions={{
          onSuccess: () => {
            notify('招待メールを送信しました', { type: 'success', undoable: false });
          },
          onError: handleError
        }}
      >
        <Box mt={3} mb={10}>
          <Stack spacing={3} sx={{ maxWidth: '1200px', margin: '0 auto' }}>
            <BorderStack>
              <Typography variant="h5" gutterBottom>
                一括招待メール送信
              </Typography>
              <Typography variant="body1" gutterBottom>
                被招待者のCSVファイルを読み込んで招待メールを送信してください。
                <br />
                CSVファイルのテンプレートは<a href="/template.csv">こちら</a>からダウンロードしてください。
                <br />
                最大1000件まで一括送信可能です。
              </Typography>
              <SimpleForm onSubmit={handleInputCSV} toolbar={<CustomToolbarCSV />} sx={{ p: 0 }}>
                <FileUploadInput
                  sx={{ mt: 2, mb: 2, '& .MuiFormHelperText-root': { whiteSpace: 'pre' } }}
                  label={false}
                  source="file"
                  accept={['.csv']}
                  validate={required()}
                ></FileUploadInput>
              </SimpleForm>
            </BorderStack>

            <BorderStack>
              <Typography variant="h5" gutterBottom>
                個別招待メール送信
              </Typography>
              <Typography variant="body1" gutterBottom>
                以下情報を入力し、新規パートナー希望者に招待メールを送信してください。
              </Typography>
              <SimpleForm toolbar={false} sx={{ maxWidth: '600px' }}>
                <FormValidation requiredFields={requiredFields}>
                  {(isFormValid) => (
                    <>
                      <TextInput source="name" fullWidth validate={required()} />
                      <TextInput source="email" fullWidth validate={required()} />
                      <PartnerRewardText />
                      <CustomToolbar isFormValid={isFormValid} />
                    </>
                  )}
                </FormValidation>
              </SimpleForm>
            </BorderStack>

            <BorderStack>
              <Typography variant="h5" gutterBottom>
                二次パートナー招待メール送信
              </Typography>
              <Typography variant="body1" gutterBottom>
                以下情報を入力し、新規二次パートナー希望者に招待メールを送信してください。
                <br />
                新規パートナーの親パートナーを指定することが可能です。
              </Typography>
              <SimpleForm toolbar={false} sx={{ maxWidth: '600px' }}>
                <FormValidation requiredFields={requiredFields}>
                  {(isFormValid) => (
                    <>
                      <InvitablePartnersSelectInput
                        label={translate('resources.invitations.fields.parentPartner')}
                        source="partnerId"
                      />
                      <TextInput source="name" fullWidth validate={required()} />
                      <TextInput source="email" fullWidth validate={required()} />
                      <PartnerRewardText />
                      <PercentNumberInput source="introducerReward" defaultValue={'0'} validate={required()} />
                      <CustomToolbar isFormValid={isFormValid} />
                    </>
                  )}
                </FormValidation>
              </SimpleForm>
            </BorderStack>
          </Stack>
        </Box>
      </CreateBase>
    </>
  );
};
