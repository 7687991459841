import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import UserIcon from '@mui/icons-material/Group';
import HandshakeIcon from '@mui/icons-material/Handshake';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import LinkIcon from '@mui/icons-material/Link';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Box from '@mui/material/Box';
import { MenuItemLink, useDataProvider, usePermissions, useSidebarState, useTranslate } from 'react-admin';
import { getLocalStorageId, hasPermission } from './authProvider';
import { ROLE_ADMIN, ROLE_EDIT, ROLE_SALES } from './common_modules/constants/define';
import Badge from '@mui/material/Badge';
import { useEffect, useState } from 'react';
import SubMenu from './common_modules/components/SubMenu';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

interface CustomMenuProps {
  dense?: boolean;
}

type MenuName = 'menuAnalytics';

const CustomMenu = ({ dense = false }: CustomMenuProps) => {
  const [state, setState] = useState({
    menuAnalytics: true
  });
  const translate = useTranslate();
  const [open] = useSidebarState();
  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();

  const [newAffiliateCount, setNewAffiliateCount] = useState(0);
  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  // const handleSubMenuClick = () => {
  //   navigate('/analytics');
  //   handleToggle('menuAnalytics');
  // };

  useEffect(() => {
    if (!hasPermission(permissions, ROLE_EDIT)) {
      return; // 権限がない場合は何も実行しない
    }

    const fetchData = async () => {
      const data = await dataProvider.getList('partners', {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'ASC' },
        filter: { applyStatus: 1 }
      });
      const total = data?.total ?? 0;
      if (total > 0) {
        setNewAffiliateCount(total);
      } else {
        setNewAffiliateCount(0);
      }
    };
    fetchData();
  }, [dataProvider, permissions]);

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        height: 'calc(100vh - env(safe-area-inset-bottom))',
        marginTop: 1,
        marginBottom: 1,
        overflowY: 'auto',
        WebkitOverflowScrolling: 'touch',
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          })
      }}
    >
      {hasPermission(permissions, ROLE_ADMIN) && (
        <MenuItemLink
          to="/accounts"
          primaryText={translate(`resources.accounts.name`)}
          leftIcon={<UserIcon />}
          dense={dense}
        />
      )}
      {hasPermission(permissions, ROLE_EDIT) && (
        <MenuItemLink
          to="/invitations"
          primaryText={translate(`resources.invitations.name`)}
          leftIcon={<MailOutlineIcon />}
          dense={dense}
        />
      )}
      {hasPermission(permissions, ROLE_EDIT) && (
        <MenuItemLink
          to="/partners"
          primaryText={translate(`resources.partners.name`)}
          leftIcon={<HandshakeIcon />}
          dense={dense}
        >
          {newAffiliateCount > 0 && (
            <Badge
              badgeContent={newAffiliateCount}
              color="primary"
              sx={{ '& .MuiBadge-badge': { right: '-8px', top: '20%' } }}
            >
              {translate(`resources.partners.name`)}
            </Badge>
          )}
        </MenuItemLink>
      )}
      <SubMenu
        handleToggle={() => handleToggle('menuAnalytics')}
        isOpen={state.menuAnalytics}
        name="resources.analytics.name.analytics"
        icon={<LeaderboardIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/all-analytics"
          primaryText={translate(`resources.analytics.name.all`)}
          leftIcon={<LeaderboardIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/partners-analytics"
          primaryText={translate(`resources.analytics.name.partners`)}
          leftIcon={<LeaderboardIcon />}
          dense={dense}
        />
      </SubMenu>
      {hasPermission(permissions, ROLE_SALES) && (
        <MenuItemLink
          to="/orders"
          primaryText={translate(`resources.orders.name`)}
          leftIcon={<ShoppingCartIcon />}
          dense={dense}
        />
      )}
      {hasPermission(permissions, ROLE_SALES) && (
        <MenuItemLink
          to="/invoices"
          primaryText={translate(`resources.invoices.name`)}
          leftIcon={<AttachMoneyIcon />}
          dense={dense}
        />
      )}
      {hasPermission(permissions, ROLE_ADMIN) && (
        <MenuItemLink
          to={`/settings/${getLocalStorageId()}`}
          primaryText={translate(`resources.settings.name`)}
          leftIcon={<SettingsIcon />}
          dense={dense}
        />
      )}
      {hasPermission(permissions, ROLE_EDIT) && (
        <MenuItemLink
          to={`/invite-url/${getLocalStorageId()}/show`}
          primaryText={translate(`resources.settings.fields.inviteCodeUrl`)}
          leftIcon={<LinkIcon />}
          dense={dense}
        />
      )}
      {/* EDITは表示はできるが変更できず、表示する必要性がないのでADMINのみ */}
      {hasPermission(permissions, ROLE_ADMIN) && (
        <MenuItemLink
          to={`/client-url/${getLocalStorageId()}/show`}
          primaryText={translate(`resources.settings.fields.ecSiteUrl`)}
          leftIcon={<LinkIcon />}
          dense={dense}
        />
      )}
    </Box>
  );
};

export default CustomMenu;
