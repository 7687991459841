import simpleRestProvider from 'ra-data-simple-rest';
import { DataProvider, GetOneParams, GetOneResult, Identifier, RaRecord, fetchUtils } from 'react-admin';
import { getLocalStorageId } from './authProvider';
import fileDownload from 'js-file-download';

const API_URL = import.meta.env.VITE_SIMPLE_REST_URL;
const REPLACE_ID = '{clientId}';

const getRequestOptions = (method: string, body?: any) => {
  const token = localStorage.getItem('token');
  const options: RequestInit = {
    method,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    })
  };
  if (body) {
    options.body = JSON.stringify(body);
  }
  return options;
};

const mergeHeaders = (defaultHeaders: Headers, customHeaders: Headers): Headers => {
  const mergedHeaders = new Headers();

  // デフォルトのヘッダーを追加
  defaultHeaders.forEach((value, key) => {
    mergedHeaders.append(key, value);
  });

  // カスタムのヘッダーを追加し、デフォルトのヘッダーを上書き
  if (customHeaders) {
    customHeaders.forEach((value, key) => {
      mergedHeaders.set(key, value);
    });
  }

  return mergedHeaders;
};

const httpClient = (url: any, options: any = {}) => {
  const requestUrl = url.replace(REPLACE_ID, getLocalStorageId() ?? '');
  const pathname = new URL(requestUrl).pathname;
  if (pathname.startsWith('/register')) {
    return fetchUtils.fetchJson(requestUrl, options);
  } else {
    const defaultOptions = getRequestOptions('GET');
    const requestOptions = {
      ...defaultOptions,
      ...options,
      headers: mergeHeaders(defaultOptions.headers as Headers, options.headers as Headers)
    };
    return fetchUtils.fetchJson(requestUrl, requestOptions);
  }
};

export const getMeData = async () => {
  const response = await fetch(import.meta.env.VITE_SIMPLE_REST_URL + '/me', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
  if (!response.ok) {
    throw {
      code: 'MeEndpointException'
    };
  }
  return await response.json();
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of dataprovider decoration.
 */
const convertFileToBase64 = (file: File | Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const convertFilesInObjectToBase64 = async (obj: any): Promise<any> => {
  if (obj !== null && typeof obj === 'object') {
    const entries = Object.entries(obj);
    const result: any = {};
    for (const [key, value] of entries) {
      if (value && typeof value === 'object') {
        if ('rawFile' in value && value.rawFile instanceof File) {
          const base64 = await convertFileToBase64(value.rawFile);
          result[key] = base64;
        } else if ('src' in value && typeof value.src === 'string') {
          result[key] = value.src;
        } else {
          result[key] = value;
        }
      } else {
        result[key] = value;
      }
    }
    return result;
  }

  return obj;
};

const uploadFile = async (data: File, urlSub: string) => {
  const clientId = getLocalStorageId();
  const url = `${API_URL}/${clientId}/${urlSub}`;

  const body = new FormData();
  body.append('file', data);

  const token = localStorage.getItem('token');
  const options: RequestInit = {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`
    }),
    body: body
  };

  const response = await fetch(url, options);
  if (!response.ok) {
    let bodyError: any = {};
    try {
      const error = await response.json();
      bodyError = { errors: { file: error.message } };
    } catch (error) {
      throw new Error('Network response was not ok');
    }
    throw bodyError;
  }
  return await response.json();
};

const baseDataProvider = simpleRestProvider(API_URL + '/' + REPLACE_ID, httpClient);

export const dataProvider: DataProvider = {
  ...baseDataProvider,

  update: async (resource, params) => {
    const dataWithBase64 = await convertFilesInObjectToBase64(params.data);
    return baseDataProvider.update(resource, {
      ...params,
      data: dataWithBase64
    });
  },

  getData: async (resource: string) => {
    const clientId = getLocalStorageId();
    const url = `${API_URL}/${clientId}/${resource}`;
    const response = await fetchUtils.fetchJson(url, getRequestOptions('GET'));
    return response.json;
  },

  getBlob: async (resource: string): Promise<Blob> => {
    const clientId = getLocalStorageId();
    const url = `${API_URL}/${clientId}/${resource}`;

    const token = localStorage.getItem('token') ?? undefined;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/octet-stream',
        Authorization: `Bearer ${token}`
      })
    });
    return await response.blob();
  },

  getOne: async <RecordType extends RaRecord<Identifier>>(
    resource: string,
    params: GetOneParams<RecordType>
  ): Promise<GetOneResult<RecordType>> => {
    const requestUrl = `${API_URL}/${REPLACE_ID}/${resource}/${params.id}`;
    return httpClient(requestUrl).then(({ json }) => ({
      data: json
    }));
  },

  createAccounts: async (data: { name: string; email: string; initialPassword: string }) => {
    const clientId = getLocalStorageId();
    const url = `${API_URL}/${clientId}/accounts`;
    const response = await fetch(url, getRequestOptions('POST', data));
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  },

  bulkInvitations: async (data: File) => {
    return uploadFile(data, 'bulkInvitations');
  },

  getReportData: async (startDate: string, endDate: string, dateType: number, resultType: number) => {
    try {
      const clientId = getLocalStorageId();
      const url = `${API_URL}/${clientId}/reports?startDate=${startDate}&endDate=${endDate}&dateType=${dateType}&resultType=${resultType}`;
      const response = await fetchUtils.fetchJson(url, getRequestOptions('GET'));
      return response.json;
    } catch (error) {
      console.error('Error in getReportData:', error);
      throw error;
    }
  },

  download: async (url: string) => {
    const token = localStorage.getItem('token') ?? undefined;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/octet-stream',
        Authorization: `Bearer ${token}`
      })
    });

    const contentDisposition = response.headers.get('Content-Disposition');
    let fileName = 'untitled';
    if (contentDisposition && contentDisposition.includes('filename=')) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch && fileNameMatch.length === 2) {
        fileName = fileNameMatch[1];
      }
    }

    const blob = await response.blob();
    fileDownload(blob, fileName);
  },
  //デフォルトはPOST
  register: async (data: Record<string, any>, url: string, method: string = 'POST') => {
    const dataWithBase64 = method === 'POST' ? await convertFilesInObjectToBase64(data) : data;
    const absoluteUrl = new URL(url, API_URL).toString();
    return httpClient(absoluteUrl, {
      method,
      body: method === 'POST' ? JSON.stringify(dataWithBase64) : undefined
    }).then(({ json }) => ({
      data: json
    }));
  }
};

export default dataProvider;
