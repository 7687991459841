import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslate } from 'react-admin';
import RegisterLayout from '../common_modules/components/RegisterLayout';
import SiteLogo from '../common_modules/components/SiteLogo';

const ClientRegisterError: React.FC = () => {
  const translate = useTranslate();
  return (
    <RegisterLayout>
      <Container>
        <Helmet>
          <title>{translate('resources.registers.name.Error')}</title>
        </Helmet>
        <SiteLogo />
        <Box display="flex" alignItems="center" sx={{ padding: 2 }}>
          <ErrorOutlineIcon sx={{ marginRight: 1 }} />
          <Typography variant="h5" align="left" sx={{ fontFamily: 'Noto Sans JP' }}>
            無効なURLです。
          </Typography>
        </Box>
      </Container>
    </RegisterLayout>
  );
};

export default ClientRegisterError;
