import { Admin, Resource, Layout, CustomRoutes } from 'react-admin';
import { dataProvider } from './dataProvider';
import UserIcon from '@mui/icons-material/Group';
import HandshakeIcon from '@mui/icons-material/Handshake';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { StaffCreate, StaffEdit, StaffList, StaffShow } from './resources/Accounts';
import { japaneseMessages } from './i18n/ja';
import authProvider from './authProvider';
import CustomMenu from './Menu';
import Dashboard from './resources/Dashboard';
import { SettingEdit } from './resources/Settings';
import { InvitationCreate, InvitationList } from './resources/Invitations';
import { OrderList, OrderShow } from './resources/Orders';
import { AffiliatesList, AffiliateEdit } from './resources/Affiliates';
import { InvoiceList, InvoiceShow } from './resources/Invoices';
import { InviteUrlShow } from './resources/InviteUrl';
import { ClientUrlEdit } from './resources/ClientUrl';
import CustomLoginPage from './common_modules/components/login/CustomLoginPage';
import Analytics from './common_modules/components/Analytics';
import theme from './theme';
import CreateStaff from './common_modules/components/CreateStaff';
import SMSAuthentication from './common_modules/components/SMSAuthentication';
import Completion from './common_modules/components/Completion';
import ClientRegister from './register/ClientRegister';
import ClientRegisterError from './register/ClientRegisterError';
import CustomAppBar from './common_modules/components/CustomAppBar';
import ForgotPasswordPage from './common_modules/components/login/ForgotPasswordPage';
import ResetPasswordSuccessPage from './common_modules/components/login/ResetPasswordSuccessPage';
import { Route } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ClientRegisterTokenError from './register/ClientRegisterTokenError';
import StartRegistration from './register/StartRegistration';
import Terms from './register/Terms';
import Contract from './register/Contract';
import Form from './register/Form';
import FinishRegistration from './register/FinishRegistration';
import Verification from './register/Verification';
import Confirmation from './register/Confirmation';
import AuthHelper from './common_modules/utils/AuthHelper';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { jaJP } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

AuthHelper.init(
  import.meta.env.VITE_COGNITO_ENDPOINT,
  import.meta.env.VITE_CLIENT_USER_POOL_ID,
  import.meta.env.VITE_CLIENT_USER_POOL_CLIENT_ID
);

dayjs.locale('ja');
const reCaptchaKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;
export const i18nProvider = polyglotI18nProvider(() => japaneseMessages, 'ja');
const layout = (props: any) => <Layout {...props} appBar={CustomAppBar} menu={CustomMenu} />;
export const App = () => (
  <LocalizationProvider
    dateAdapter={AdapterDayjs}
    adapterLocale="ja"
    localeText={jaJP.components.MuiLocalizationProvider.defaultProps.localeText}
  >
    <Admin
      layout={layout}
      dashboard={Dashboard}
      loginPage={() => <CustomLoginPage subtitle="for EC" backgroundColor="#78605C" buttonColor="#78605C" />}
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      authProvider={authProvider}
      theme={theme}
    >
      <CustomRoutes noLayout>
        <Route
          path="/reset-password"
          element={<ForgotPasswordPage backgroundColor="#EDECED" buttonColor="#C59F98" />}
        />
        <Route
          path="/reset-complete"
          element={<ResetPasswordSuccessPage backgroundColor="#EDECED" buttonColor="#C59F98" />}
        />
      </CustomRoutes>

      <CustomRoutes noLayout>
        <Route path="/register/client" element={<ClientRegister />} />
        <Route path="/register/client-staff" element={<ClientRegister />} />
        <Route path="/register/client/start-register" element={<StartRegistration />} />
        <Route path="/register/client/terms" element={<Terms />} />
        <Route path="/register/client/contract" element={<Contract />} />
        <Route path="/register/client/form" element={<Form />} />
        <Route
          path="/register/client/confirm"
          element={
            <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
              <Confirmation />
            </GoogleReCaptchaProvider>
          }
        />
        {/* <Route path="/register/partner/upload-id" element={<UploadId />} /> */}
        <Route path="/register/client/finish" element={<FinishRegistration />} />

        <Route path="/register/client/create-staff" element={<CreateStaff />} />
        <Route path="/register/client/smsauth" element={<SMSAuthentication />} />
        <Route path="/register/client/complete" element={<Completion />} />
        <Route path="/register/client/verification" element={<Verification />} />

        <Route path="/register/client/error" element={<ClientRegisterError />} />
        <Route path="/register/client/token-error" element={<ClientRegisterTokenError />} />

        <Route path="/register/client-staff/create-staff" element={<CreateStaff />} />
        <Route path="/register/client-staff/smsauth" element={<SMSAuthentication />} />
        <Route path="/register/client-staff/complete" element={<Completion />} />
        <Route path="/register/client-staff/error" element={<ClientRegisterError />} />
      </CustomRoutes>

      <CustomRoutes>
        {/* <Route path="/analytics" element={<Analytics />} /> */}
        <Route path="/all-analytics" element={<Analytics selectedResultType={1} isAdmin={false} />} />
        <Route path="/partners-analytics" element={<Analytics selectedResultType={3} />} />
      </CustomRoutes>

      <Resource
        name="accounts"
        list={StaffList}
        show={StaffShow}
        edit={StaffEdit}
        create={StaffCreate}
        icon={UserIcon}
      />

      <Resource name="invitations" list={InvitationList} create={InvitationCreate} />

      <Resource name="partners" list={AffiliatesList} edit={AffiliateEdit} icon={HandshakeIcon} />

      <Resource name="orders" list={OrderList} show={OrderShow} icon={UserIcon} />

      <Resource name="invoices" list={InvoiceList} show={InvoiceShow} icon={UserIcon} />

      <Resource name="settings" edit={SettingEdit} icon={UserIcon} />

      <Resource name="invite-url" show={InviteUrlShow} />

      <Resource name="client-url" show={ClientUrlEdit} />
    </Admin>
  </LocalizationProvider>
);
