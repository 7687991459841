import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Container, Typography, Stack } from '@mui/material';
import { Helmet } from 'react-helmet';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { getRegisterFormData } from '../common_modules/components/Common';
import RegisterLayout from '../common_modules/components/RegisterLayout';
import RegisterHeaderProgress from '../common_modules/components/RegisterHeaderProgress';

interface RegisterFormData {
  email?: string;
  mobilePhone?: string;
}

const FinishRegistration: React.FC = () => {
  const translate = useTranslate();

  const [tel, setTel] = useState<string | undefined>('');
  const [email, setEmail] = useState<string | undefined>('');

  useEffect(() => {
    const formData = getRegisterFormData() as RegisterFormData;
    if (formData) {
      setEmail(formData.email);
      setTel(formData.mobilePhone);
    }
  }, []);

  return (
    <RegisterLayout>
      <Container>
        <Helmet>
          <title>{translate('resources.registers.name.Completion')}</title>
        </Helmet>
        <Stack spacing={1}>
          <RegisterHeaderProgress
            title={'resources.registers.name.Completion'}
            icon={<CheckCircleOutlineIcon />}
            step={7}
            totalSteps={7}
          />

          <Typography>
            ECサイト登録の申請ありがとうございます。
            <br />
            <span>
              ご登録のメールアドレス({email})へ本人確認のメールが送信されましたので、ご確認をお願いいたします。
            </span>
          </Typography>
        </Stack>
      </Container>
    </RegisterLayout>
  );
};

export default FinishRegistration;
